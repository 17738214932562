import { selectCity, selectMetersCount } from '../features';
import { CityCode } from '../services';
import { useAppSelector } from '../store/hooks';

export const useShouldDisplayParkingLimit = () => {
  const metersCount = useAppSelector(selectMetersCount);
  const city = useAppSelector(selectCity);

  return (metersCount === null || metersCount > 0) && city?.Code !== CityCode.SanJose;
};
