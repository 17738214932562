import { Action } from '@reduxjs/toolkit';

import { combineEpics } from 'redux-observable';
import { Observable, catchError, filter, from, map, mergeMap, of, switchMap } from 'rxjs';
import { occupancyData, revenueData } from '../../../../services';
import { citiesActions } from '../../../common';
import { heatmapsFilterActions } from './heatmaps-slice';
import { durationData } from '../../../../services/api/duration';

const fetchSourcesEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(heatmapsFilterActions.fetchSources.match),
    switchMap((action) =>
      from(occupancyData.getSources()).pipe(
        map((x) => heatmapsFilterActions.fetchSourcesSuccess(x)),
        catchError((err) => of(heatmapsFilterActions.fetchSourcesFailed(err.message))),
      ),
    ),
  );
};

const fetchHasRevenueHeatmapEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(heatmapsFilterActions.fetchRevenueHeatmapLayers.match),
    switchMap((_) =>
      from(revenueData.getRevenueHeatmapLayers()).pipe(
        map((x) => heatmapsFilterActions.fetchRevenueHeatmapLayersSuccess(x)),
        catchError((err) => of(heatmapsFilterActions.fetchRevenueHeatmapLayersFailed(err.message))),
      ),
    ),
  );
};

const fetchHasDurationHeatmapEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(heatmapsFilterActions.fetchHasDurationHeatmap.match),
    switchMap((_) =>
      from(durationData.getHasDurationHeatmap()).pipe(
        map((x) => heatmapsFilterActions.fetchHasDurationHeatmapSuccess(x.Value)),
        catchError((err) => of(heatmapsFilterActions.fetchHasDurationHeatmapFailed(err.message))),
      ),
    ),
  );
};

const citySelectedEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap(() =>
      of(
        heatmapsFilterActions.fetchSources(),
        heatmapsFilterActions.fetchRevenueHeatmapLayers(),
        heatmapsFilterActions.fetchHasDurationHeatmap(),
      ),
    ),
  );
};

export const heatmapEpic = combineEpics(citySelectedEpic, fetchSourcesEpic, fetchHasRevenueHeatmapEpic, fetchHasDurationHeatmapEpic);
