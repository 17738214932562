import { Action } from '@reduxjs/toolkit';
import { Observable, of, from, zip } from 'rxjs';
import { map, filter, concatMap, catchError, mergeMap, tap } from 'rxjs/operators';
import { StateObservable, combineEpics } from 'redux-observable';
import { Feature, GeoJsonProperties, Polygon, Geometry } from 'geojson';

import { areas, offstreetZones, revenueData, zones } from '../../../../services/api';
import { areaTypesActions } from './area-types-slice';
import { areaGeoActions } from './area-geo-slice';
import { areaLayerActions } from './area-layer-slice';
import { RootState, store } from '../../../../store';
import { AreaFeatureCollection, IAreaType, ISelectedArea } from '../../../../model';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { selectedAreaActions } from './selected-area-slice';
import { geoUtils } from '../../../../utils';
import { citiesActions } from '../../../common';
import { mapStateActions } from '../../map-state';

export interface IAreaTypeNameById {
  [id: number]: { level: number; name: string; pluralName: string };
}

const getAreaTypesEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(areaTypesActions.fetchAreaTypes.match),
    concatMap(() =>
      from(areas.getAllTypes()).pipe(
        mergeMap((x) => of(areaTypesActions.fetchAreaTypesSuccess(x), areaLayerActions.setDisabled())),
        catchError((err) => of(areaTypesActions.fetchAreaTypesFailed(err.message))),
      ),
    ),
  );

const getAreaLevelGeoEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(areaGeoActions.fetch.match),
    concatMap((action) =>
      loadLevel(action.payload).pipe(
        map((x) => areaGeoActions.fetchSuccess(x)),
        catchError((err) => of(areaGeoActions.fetchFailed(err.message))),
      ),
    ),
  );

const areaLevelDisabledEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(areaLayerActions.setDisabled.match),
    map((action) => areaGeoActions.fetchSuccess(EMPTY_FEATURE_COLLECTION)),
  );

const areaSelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(selectedAreaActions.loadArea.match),
    concatMap((action) =>
      loadArea(action.payload.id, store.getState()).pipe(
        mergeMap((x) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const areaLevel = store.getState().areaTypes.levels.find((l) => l.id === x.typeLevel)!;
          if (action.payload.position)
            return of(
              areaLayerActions.setEnabled({ level: areaLevel }),
              selectedAreaActions.loadAreaSuccess({ area: x, position: action.payload.position }),
            );

          const feature = findAreaFeature(action.payload.id) || {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [x.Positions],
            },
            properties: {},
          };
          const center = geoUtils.findCenter(feature.geometry).coordinates;

          return of(
            areaLayerActions.setEnabled({ level: areaLevel }),
            mapStateActions.setMapCenter(center),
            selectedAreaActions.loadAreaSuccess({ area: x, position: center }),
          );
        }),
        catchError((err) => of(selectedAreaActions.loadAreaFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    map((action) => selectedAreaActions.closePopup()),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedAreaActions.closePopups()),
  );

const loadLevel = (level: number): Observable<AreaFeatureCollection> => {
  return zip(from(areas.getLevelPolygons(level)), getTypes()).pipe(
    map((res) => {
      const features: Array<Feature<Polygon, GeoJsonProperties>> = res[0].map((f) => {
        const type = res[1].find((x) => x.Id === f.TypeId);
        return {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [f.Positions],
          },
          properties: {
            id: f.Id,
            typeId: f.TypeId,
            name: f.Name,
            typeName: type?.Name,
            typeLevel: type?.Level,
          },
        };
      });

      const result: AreaFeatureCollection = {
        level: level,
        type: 'FeatureCollection',
        features: features,
      };

      return result;
    }),
  );
};

const getTypes = (): Observable<IAreaType[]> => {
  const state = store.getState();
  if (state.areaTypes.data.length > 0) {
    return of(state.areaTypes.data);
  }

  return from(areas.getAllTypes()).pipe(tap((x) => store.dispatch(areaTypesActions.fetchAreaTypesSuccess(x))));
};

const loadArea = (id: number, state: RootState): Observable<ISelectedArea> => {
  const existing = state.selectedAreas.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return zip(
    areas.get(id),
    getTypes(),
    zones.getAreaZoneNames(id),
    offstreetZones.getNamesByAreaId(id),
    revenueData.getRevenueByArea(id),
  ).pipe(
    map(([area, types, zones, offstreetZones, revenue]) => {
      const areaTypes = getAreaTypes(types);
      const areaType: { level: number; name: string; pluralName: string } = areaTypes[area.TypeId];
      return {
        ...area,
        typeName: areaType?.name,
        typeLevel: areaType?.level,
        zoneNames: zones,
        revenue: revenue,
        offstreetZoneNames: offstreetZones,
      };
    }),
  );
};

const findAreaFeature = (areaId: number): Feature<Geometry, GeoJsonProperties> | null => {
  const state = store.getState();
  return state.areaGeo.data.features.find((x) => x.properties?.id === areaId) || null;
};

export const getAreaTypes = (areaTypes: Array<IAreaType>): IAreaTypeNameById => {
  let result: IAreaTypeNameById = {};

  areaTypes.forEach((t) => {
    result[t.Id] = { level: t.Level, name: t.Name, pluralName: t.PluralName };

    if (t.Descendants && t.Descendants.length > 0) {
      result = { ...result, ...getAreaTypes(t.Descendants) };
    }
  });

  return result;
};

export const areaEpic = combineEpics<Action>(
  getAreaLevelGeoEpic,
  getAreaTypesEpic,
  areaLevelDisabledEpic,
  areaSelectedEpic,
  citySelectedEpic,
  closePopupsEpic,
);
